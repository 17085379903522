import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl } from '../../environment';

function configureAxiosGlobalSettings(): void {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.withCredentials = true;
}

export function createAxiosInstance(): AxiosInstance {
    configureAxiosGlobalSettings();

    const configuration: AxiosRequestConfig = {};

    configuration.baseURL = baseUrl;
    configuration.withCredentials = true;

    return axios.create(configuration);
}
