import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { referrer } from '../environment';
import { useAxios, usePathInterceptor } from '../lib/axios';
import { useAppVersionInterceptor } from '../lib/shared/hooks';
import './app.scss';
import { RoutedContent, Routing } from './routing';
import { useSearchParams } from 'react-router-dom';

export const App: React.FC = () => {
    usePathInterceptor();
    useAppVersionInterceptor();

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const sId = searchParams.get('s_id');
    const axios = useAxios();

    const openRedirectToReferrer = (): void => {
        if (
            window.location.href.indexOf(Routing.success) > 0 ||
            window.location.href.indexOf(Routing.abort) > 0
        ) {
            redirectToReferrer();
        } else {
            setModalIsOpen(true);
        }
    };

    const redirectToReferrer = (): void => {
        window.location.href = referrer || '';
    };

    useEffect(() => {
        const execute = async (): Promise<void> => {
            if (!sId) {
                return;
            }

            searchParams.delete('s_id');
            setSearchParams(searchParams);
            await axios.post('api/submitTrackId', { sId }, { withCredentials: true });
        };

        execute();
    }, [sId, axios, setSearchParams, searchParams]);

    return (
        <div id='app-container' className='app-container'>
            <div className='page-header'>
                <button className='header-image' onClick={openRedirectToReferrer}>
                    <img
                        width='2595'
                        height='331'
                        src='static/images/header.png'
                        alt=''
                        sizes='(max-width: 2595px) 100vw, 2595px'
                        style={{ cursor: 'pointer', width: 'auto', height: '27px' }}
                    />
                </button>
                <div style={{ flexGrow: 1 }} />
            </div>
            <RoutedContent />
            <Modal
                title='Wirklich verlassen?'
                open={modalIsOpen}
                onOk={redirectToReferrer}
                onCancel={(): void => setModalIsOpen(false)}
                cancelText='Abbrechen'
            >
                <p>{'Alle Eingaben, die Sie auf dieser Seite getätigt haben gehen verloren!'}</p>
                <p>{'Möchten Sie fortfahren?'}</p>
            </Modal>
        </div>
    );
};
